.button-overline {
  display: inline-block;
  cursor: pointer;

  font-family: "Montserrat", sans-serif;
  /* font-size: 1.2rem; */
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: capitalize;
  text-align: center;
  color: #141e30;

  margin: 10px;
  margin-left: opx;
  padding: 10px 20px;
  width: fit-content;

  transition: 0.2s;
  background-size: 200% auto;

  border: 2px solid #141e30;
  border-radius: 3px;
}

.button-overline:hover {
  transform: translateY(-2px);
  background-image: linear-gradient(
    to right,
    #141e30 0%,
    #243b55 51%,
    #141e30 100%
  );
  color: #f9f9f9;
}
