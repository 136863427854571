@keyframes ldio-5hog4dtga4y {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ldio-5hog4dtga4y div {
  position: absolute;
  animation: ldio-5hog4dtga4y 1s linear infinite;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 #93dbe9;
  transform-origin: 80px 82px;
}
.loadingio-spinner-eclipse-4bp3mo2d5ft {
  position: fixed;
  top: 45%;
  left: 45%;
  z-index: 999;
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-5hog4dtga4y {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-5hog4dtga4y div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
