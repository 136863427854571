.social-card-container {
  position: fixed;
  top: 40%;
  left: 0%;
  z-index: 999;

  display: flex;
  flex-direction: column;
  align-items: center;

  /* width: 50px; */
  border-radius: 3px;

  background-color: #64ccc5;
}

.social-card-icon a i {
  font-size: 3em;
  color: white;
  padding: 0px 3px;
  margin: 8px;
  transition: 0.2s ease-in-out;
}

.social-card-icon a i:hover {
  color: #053b50;
  transform: scale(1.1);
}

@media screen and (max-width: 998px) {
  .social-card-container {
    display: none;
  }
}
