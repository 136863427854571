.contact-body {
  height: fit-content;
  background-color: #f4f4f4;
  background: linear-gradient(135deg, #f4fbff55 25%, transparent 25%) -100px 0/
      200px 200px,
    linear-gradient(225deg, #f4fbff 25%, transparent 25%) -100px 0/ 200px 200px,
    linear-gradient(315deg, #f4fbff55 25%, transparent 25%) 0px 0/ 200px 200px,
    linear-gradient(45deg, #f4fbff 25%, #ffffff 25%) 0px 0/ 200px 200px;
}

.contact-container {
  padding: 35px 0px;
}

.contact-container .contact-heading h2 {
  margin: 0px;
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  text-align: center;
  padding: 35px 0px 25px 0px;
}

.contact-container .contact-heading .contact-heading-underline {
  width: 2%;
  height: 5px;
  border-radius: 15px;
  margin: auto;
  background-color: #053b50;
}

.contact-container .contact-description p {
  margin: 0px 35px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2em;
  text-align: center;
  padding: 35px 0px 25px 0px;
}

.contact-container .contact-body-form {
  margin: auto;
  width: 60%;
  background-color: whitesmoke;
  padding: 25px 50px;
  border-radius: 3px;
}

.contact-container .contact-body-form form label {
  margin-top: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.9em;
  display: block;
  width: 100%;
}
.contact-container .contact-body-form form input {
  font-family: "Montserrat", sans-serif;
  display: block;
  width: 100%;
  padding: 10px;
  margin: 5px 0px;
  border-radius: 3px;
  border: none;
}

.contact-container .contact-body-form form textarea {
  font-family: "Montserrat", sans-serif;
  display: block;
  width: 100%;
  padding: 10px;
  width: 100%;
  height: 200px;
  margin: 5px 0px;
  border-radius: 3px;
  border: none;
}

#contact button {
  cursor: pointer;
  transition: 0.2s ease;
}
#contact button:hover {
  transform: translateY(-3px);
}

textarea {
  resize: none;
}

.contact-container .contact-body-form .contact-form-submit-button {
  font-family: "Montserrat", sans-serif;
  margin-top: 10px;
  text-align: center;
  padding: 1em 3em;
  border: none;
  border-radius: 3px;
  background-color: #071952;
  color: white;
}

@media (max-width: 998px) {
  .contact-container .contact-body-form {
    width: 75%;
  }
}
@media (max-width: 768px) {
  .contact-container .contact-body-form {
    width: 90%;
  }
}
