.player-body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  border-radius: 15px;
  border-color: aqua;
  border-width: 5px;

  font-family: "Montserrat", sans-serif;

  background-color: #272829;
}

.player-body .player-text-body {
  padding: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.player-body .player-text-body .player-on-repeat p {
  margin: 0;
  color: #fff6dc;
  opacity: 0.7;
}

.player-body .player-text-body a {
  margin: 0;
  text-decoration: none;
  color: #fff6dc;
}

.player-body .player-spotify-icon {
  font-size: 2.5em;
  color: #1ed760;
  padding: 10px;
  opacity: 0.7;
}
