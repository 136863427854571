.footer-body {
  margin: 0;
  width: 100%;
  height: 300px;
  background-color: #000000;
  color: #f9f9f9;
}

.footer-body .footer-body-container {
  margin: auto;
  margin-bottom: 75px;
  padding: 0px 0px;
  padding-top: 50px;
  padding-bottom: 25px;
  width: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer-body .footer-body-container .footer-body-about {
  font-family: "Montserrat", sans-serif;
}
.footer-body .footer-body-container .footer-body-about h3 {
  margin-bottom: 15px;
}
.footer-body .footer-horizontal-rule {
  height: 1px;
  width: 60%;
  background-color: #f9f9f9;
  margin: auto;
}

.footer-body .footer-body-copyright-text {
  padding-top: 15px;
  padding-bottom: 5px;
  font-family: "Ubuntu Mono", monospace;
  font-size: 0.8em;
  text-align: center;
}

@media (max-width: 768px) {
  .footer-body .footer-body-container {
    flex-direction: column;
    align-items: start;
    width: 90%;
  }
  .footer-body .footer-body-container .footer-body-about {
    margin-bottom: 50px;
  }
  .footer-body {
    height: 390px;
  }
}
